import axios from 'axios'
//引入进度条插件
import nprogress from 'nprogress'
//引入进度条样式
import 'nprogress/nprogress.css'
//引入当前环境的接口域名
import config from '@/config'
//引入提示组件
import { Message } from 'element-ui'
// 引入vuex
import store from '@/store/index'
// vue-cookies配置
import VueCookies from 'vue-cookies'
import { getHeaderSignature, goToUniLogin } from '@/utils/utils'
import router from '../router'
// const baseURL = config.NODE_ENV == 'test' ? '' : config.ApiUrl
var baseURL = config.ApiUrl
let isTimerLock = true // 获取系统时间开关
export async function request(config) {
    if (!VueCookies.get('timeDifference') && isTimerLock) {
        isTimerLock = false
        // let timeRes = await axios.post(baseURL+'/org/sysTime/getServerTime')
        let timeRes = await axios({
            url: baseURL + '/org/sysTime/getServerTime',
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        isTimerLock = true
        if (timeRes.data.code != 200) return Message.error({ message: timeRes.data.msg, grouping: true })
        if (Number(timeRes.data.data.time) - Date.parse(new Date()) > 3600000) {
            Message.warning({ message: '当前系统时间过慢，请重新设置，以免访问出错！', grouping: true })
        }
        VueCookies.set('timeDifference', Number(timeRes.data.data.time) - Date.parse(new Date()))
        const { vi, vn, tk, ts, ns, sg } = getHeaderSignature()
        return getAxios(config, vi, vn, tk, ts, ns, sg)
    } else {
        const { vi, vn, tk, ts, ns, sg } = getHeaderSignature()
        return getAxios(config, vi, vn, tk, ts, ns, sg)
    }
}
function getAxios(config, vi, vn, tk, ts, ns, sg) {
    // 创建axios的实例
    const instance = axios.create({
        baseURL: baseURL,
        timeout: 30000
    })

    instance.interceptors.request.use(
        (config) => {
            //进度条开启
            nprogress.start()
            //设置token请求头，确保在发送请求的时候有权限
            config.headers.userType = 'orguser'
            config.headers = { ...config.headers, vi, vn, tk, ts, ns, sg }
            return config
        },
        (err) => {
            console.log(err)
        }
    )

    instance.interceptors.response.use(
        (res) => {
            //进度条结束
            nprogress.done()
            if (res.data.code) {
                switch (Number(res.data.code)) {
                    case 2001:
                        Message.closeAll()
                        Message({ type: 'error', message: res.data.msg })
                        VueCookies.remove('token')
                        VueCookies.remove('activePath')
                        VueCookies.remove('userInfo')
                        store.commit('changeActivePath', '')
                        store.commit('renewUserInfo', {})
                        if (router.currentRoute.path != '/login') {
                            goToUniLogin()
                        }
                        break
                    case 1004:
                        Message.closeAll()
                        Message({ type: 'error', message: res.data.msg })
                        break
                    case 200:
                        break
                    case 1003:
                    case 1008:
                        VueCookies.remove('timeDifference')
                        location.reload()
                        break
                    default:
                        Message.closeAll()
                        Message({ type: 'error', message: res.data.msg })
                        break
                }
            }
            return res.data
        },
        (err) => {
            Message.closeAll()
            console.log(err)
            // VueCookies.remove("token")
            // VueCookies.remove('activePath')
            // VueCookies.remove("userInfo")
            // store.commit("renewUserInfo", {});
            Message({ type: 'error', message: '网络错误，请稍后重试' })
            nprogress.done()
        }
    )

    return instance(config)
}
